import React from "react"

import Layout from "src/components/Layout"
import SEO from "src/components/Seo"
import NotFoundComponent from "src/components/NotFound"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />

    <NotFoundComponent />
  </Layout>
)

export default NotFoundPage
